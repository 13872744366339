<template>
  <!-- Header -->
  <div class="header-home">
    <div class="user-info" style="padding-top: 20px;padding-bottom: 40px;">Store Emp.
      <strong>{{ (user.first_name ? user.first_name : '') + ' ' + (user.last_name ? user.last_name : '') }}</strong>
    </div> <!-- Replace with dynamic user data -->
  </div>

  <!-- Camps Container -->
  <div style="padding-right: 10px;padding-left: 10px;    margin-bottom: 100px;">
    <div class="container camps-container">
      <!-- Camp Item Example -->
      <template v-if="loading">
        <div class="row">
          <div class="col-md-12">
            <h3 class="text-center">Loading..</h3>
          </div>
        </div>
      </template>
      <template v-else-if="data.length" v-for="(item,index) in data">
        <div class="camp-item">
          <a href="javascript:void(0);"  class="camp-info" data-bs-toggle="modal"
             data-bs-target="#show-delivery-info"
             @click="upload_received_delivery($event,item.id,item,index)"
             style="    text-decoration: none;color: #003a48;">
            <h5 style="margin: 0;text-align: center">{{
                item.delivery_type == 'camp' ? item.camp.name : (item.customer ? item.customer.full_name : '')
              }}</h5>

            <p style="margin: 0;text-align: center">
              <span v-for="item1 in item.items"
                    style="padding: 5px;margin-right: 3px;border:1px solid rgb(220 220 220);border-radius: 3px;display: block;color: #464646;margin-bottom: 2px;margin-top: 2px">
                                            {{ item1.store_item_quantity.store_item.name }}
                                            <span
                                                style="    background-color: rgb(104, 180, 255);padding: 0 3px;color: white;margin: 0 2px;border-radius: 2px;">
                                                {{ item1.quantity }}</span>
                                             {{ item1.store_item_quantity.store_item.unit }}
                                        </span>
            </p>
            <h6 style="margin: 0;text-align: center;background-color: red;color: yellow;">{{
                item.hints_before_visit
              }}</h6>
            <p style="margin: 0;font-size: 8px" v-if="item.last_sync_at"> Last Sync: {{ item.last_sync_at }}</p>

          </a>
          <a href="javascript:void(0);"
             :style="'position: relative;'"
             :class="['btn',(item.last_sync_at ? 'sync-btn' : 'sync-btn-white'),((!is_online || loading) ) ? 'disabled' : '']"
             :disabled="(!is_online || loading)"
             data-bs-toggle="modal"
             data-bs-target="#done-delivery"
             @click="upload_received_delivery($event,item.id,item,index)">
            Done{{ item.last_sync_at ? 'd' : '' }}
            <font-awesome-icon v-if="item.last_sync_at"
                               style="color: white;    background: rgb(40, 167, 69);position: absolute;top: -15px;right: -12px;border-radius: 50%;padding: 5px;border: 3px solid white;"
                               icon="check"/>

          </a>

        </div>
      </template>
      <template v-else>
        <div class="row">
          <div class="col-md-12">
            <h3 class="text-center">No delivery notes.</h3>
          </div>
        </div>
      </template>
      <done-delivery></done-delivery>
      <show-delivery-info></show-delivery-info>

      <!-- Add more camp items as needed -->
    </div>
  </div>
</template>
<script>
import {addOrUpdateData, getAllData, clearAllData, getDataByKey} from '@/database';
import eventBus from '@/eventBus';

import Done from './Done'
import ShowDeliveryInfo from './ShowDeliveryInfo'
import pusher from '@/pusher';

export default {
  components: {'done-delivery': Done, 'show-delivery-info': ShowDeliveryInfo},
  data() {
    return {
      data: [],
      user: {},
      emits: ['custom-event'],

      showInstallButton: false,
      loading: false,
      error: null,
      is_online: navigator.onLine,
    };
  },
  mounted() {
    this.getScreenData();
    eventBus.on('refresh-list', function () {
      this.getScreenData();
    }.bind(this))
    // this.is_online = true;
    // this.is_online = navigator.onLine;
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);


    // Subscribe to a channel
    const channel = pusher.subscribe('notifications');
    // Listen for an event
    channel.bind('notification.received', (data) => {
      console.log(data, 'data')
      if (this.data.length)
        this.data.unshift(data.notification);
      else
        this.data.push(data.notification);

      console.log(this.data.items, 'this.data.items')
      this.$forceUpdate();
    });

  },
  beforeDestroy() {
    // Remove event listeners to prevent memory leaks
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  methods: {
    updateOnlineStatus() {
      this.is_online = navigator.onLine; // Update the status based on the navigator's online property
    },

    async getScreenData() {
      try {
        await getAllData('auth-user').then((data) => {
          this.user = data[0];

        })
        this.loading = true;
        var response = await this.$axios.get('/api/visits/store-current?user_id='+this.user.id);
        this.data = response.data.data
        this.loading = false;

      } catch (error) {
        console.log(error)
      }
    },
    async upload_received_delivery(event, visit_id, item, index) {
      eventBus.emit('selected_item_for_done_delivery', {
        item: item,
      })
      // try {
      //   let visit = await getDataByKey(visit_id, 'current-visits')
      //   this.loading = true;
      //   var response = await this.$axios.post('/api/visits/' + visit_id + '/sync', {
      //     data: visit,
      //     employee_id: this.user.employee_id
      //   });
      //   if (response.data.status == true) {
      //     this.loading = false;
      //
      //     visit.last_sync_at = response.data.last_sync_at
      //     item.last_sync_at = response.data.last_sync_at;
      //     const cleanData = JSON.parse(JSON.stringify(visit));
      //     await addOrUpdateData(cleanData, 'current-visits')
      //   }
      //   console.log(response, 'response')
      // } catch (error) {
      //   this.loading = false;
      //   console.log(error)
      // }

    },
  }
};
</script>

<style scoped>

.header-home {
  background-color: #34657f;
  color: white;
  padding: 0px;
  text-align: center;
  border-radius: 0 0 20px 20px;
}

.user-info {
  margin: 10px 0;
  font-size: 1em;
  color: white;
}

.camps-container {
  margin-top: -30px;
  padding: 20px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.camp-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  border-bottom: 1px solid #e0e0e0;
}

.camp-item:last-child {
  border-bottom: none;
}

.camp-info {
  flex-grow: 1;
  margin-right: 15px;
  text-align: left;
}

.sync-btn {
  background-color: #28a745;
  color: white;
  border-radius: 25px;
  padding: 10px 15px;
}

.sync-btn-white {
  background-color: #34657f;
  color: white;
  border-radius: 25px;
  padding: 10px 15px;
  border: 1px solid #34657f !important;
}

.sync-btn:hover {
  background-color: #218838;
}
</style>
