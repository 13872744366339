// src/router/index.js
import {createRouter, createWebHistory} from 'vue-router';
import Home from './components/CurrentVisitScreen';
import ShowVisit from './components/Screens/Visit/Show';
import CurrentDeliveryCommodities from './components/Screens/Store/CurrentDeliveryCommodities';
import VisitAddScreen from './components/Screens/Visit/VisitAddScreen';
import SettingIndexScreen from './components/Screens/Setting/SettingIndexScreen';
import Login from './components/LoginScreen';
import Map from './components/Map/MapComponent';
import SettingStoreIndexScreen from "./components/Screens/Setting/SettingStoreIndexScreen";

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,

    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true,
            hasBackButton: false,
            header: {
                title: 'Home'
            },
        }

    },
    {
        path: '/map',
        name: 'Map',
        component: Map,
        meta: {
            requiresAuth: true,
            hasBackButton: false,
            header: {
                title: 'Map'
            },
        }

    },
    {
        path: '/visits/:id',
        name: 'ShowVisit',
        component: ShowVisit,
        meta: {
            requiresAuth: true,
            hasBackButton: true,
            header: {
                title: 'Show Visit'
            },
        }

    },
    {
        path: '/settings',
        name: 'SettingIndexScreen',
        component: SettingIndexScreen,
        meta: {
            requiresAuth: true,
            header: {
                title: 'Setting'
            },
        }

    },
    {
        path: '/store-settings',
        name: 'SettingStoreIndexScreen',
        component: SettingStoreIndexScreen,
        meta: {
            requiresAuth: true,
            header: {
                title: 'Setting'
            },
        }

    },
    {
        path: '/current-delivery-commodities',
        name: 'CurrentDeliveryCommodities',
        component: CurrentDeliveryCommodities,
        meta: {
            requiresAuth: true,
            header: {
                title: 'Current Delivery'
            },
        }

    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        header: {
            title: 'Login'
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

// Navigation guard to check for logged-in users
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        const token = localStorage.getItem('token');
        if (!token) {
            next({name: 'Login'});
        } else {
            next();
        }
    } else {

        if (to.name == 'Login') {
            const token = localStorage.getItem('token');
            if (token) {

                next({name: 'Home'});
            } else {
                next();
            }
        } else {
            if (to.href == '/') {
                console.log(to, 'to')
                next({name: 'Login'});
            } else {
                next();
            }
        }
    }
});

export default router;
