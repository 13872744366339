<template>
  <!-- Header -->
  <div class="header-home">
    <div class="user-info" style="padding-top: 20px;padding-bottom: 40px;">Monitor:
      <strong>{{ (user.first_name ? user.first_name : '') + ' ' + (user.last_name ? user.last_name : '') }}</strong>
    </div> <!-- Replace with dynamic user data -->
  </div>

  <!-- Camps Container -->
  <div style="padding-right: 10px;padding-left: 10px;    margin-bottom: 100px;">
    <div class="container camps-container">
      <!-- Camp Item Example -->
      <template v-if="data.length">
        <template v-for="(item,index) in data"   >
          <div class="camp-item" v-if="(item.camp.camp_type != 3) || (item.camp.camp_type == 3 && item.employee.id == user.employee_id)">
            <div class="camp-info" >
            <router-link :to="'/visits/'+item.id" @click.prevent
                         style="    text-decoration: none;color: #003a48;">
              <div class="mb-1">

                <h5 class="badge bg-secondary"
                    style="margin: 0;    font-size: 0.8em;padding: 3px 5px;background: #0498ab !important;">
                  {{ item.visit_date }}</h5>
                <h5 v-if="item.employee" class="badge bg-secondary mx-2"
                    style="margin: 0;    font-size: 0.8em;padding: 3px 5px;background: #ef851e !important;">
                  {{ item.employee.name }}
                </h5>
                <h5 v-else class="badge bg-secondary mx-2"
                    style="margin: 0;    font-size: 0.8em;padding: 3px 5px;background: #ef851e !important;">
                  Unknown
                </h5>
                <h5 v-if="item.hints_before_visit" class="badge bg-danger me-2"
                    style="margin: 0;    font-size: 0.8em;padding: 3px 5px;background: #ff4d4d !important;color: yellow !important;">
                  {{ item.hints_before_visit }}</h5>

              </div>
              <h5 style="margin: 0;    font-size: 1em;font-weight: bold">{{ item.camp.name_ar }}</h5>
            </router-link>

              <ul style="margin-left: 0px;padding-left: 10px;">
                <li v-if="item.camp.rep_name" style="margin: 0;    font-size: 0.8em;">Beneficiaries:
                  {{ item.customers_count }}
                </li>
                <li v-if="item.camp.rep_name" style="margin: 0;    font-size: 0.8em;">{{ item.camp.rep_name.trim() }}</li>
                <li v-if="item.camp.rep_phone" style="margin: 0;    font-size: 0.8em;">
                  <a :href="'tel:'+(item.camp.rep_phone.trim())">{{ item.camp.rep_phone.trim() }}</a>
                </li>
              </ul>

              <p style="margin: 0;font-size: 8px" v-if="item.last_sync_at"> Last Sync: {{ item.last_sync_at }}</p>

            </div>
            <button type="button"
                    :style="'position: relative;padding:10px'"
                    :class="['btn',(item.last_sync_at ? 'sync-btn' : 'sync-btn-white'),((!is_online || item.sync_loader) || (user.employee_id != item.employee_id && !$root.is_can_sync_activated) ) ? 'disabled' : '']"
                    :disabled="(!is_online || item.sync_loader) || (user.employee_id != item.employee_id && !$root.is_can_sync_activated)"
                    @click="uploadVisits($event,item.id,item,index)">
              Sync{{ item.last_sync_at ? 'd' : '' }}
              <font-awesome-icon v-if="item.last_sync_at"
                                 style="color: white;    background: rgb(40, 167, 69);position: absolute;top: -15px;right: -12px;border-radius: 50%;padding: 5px;border: 3px solid white;"
                                 icon="check"/>
              <font-awesome-icon v-else-if="(user.employee_id == item.employee_id || $root.is_can_sync_activated)"
                                 style="color: #34657f;    background: white;position: absolute;top: -15px;right: -12px;border-radius: 50%;padding: 5px;border: 1px solid #34657f;"
                                 icon="clock"/>

              <div v-if="item.sync_loader" class="spinner-border text-black"
                   style="    --bs-spinner-width: 1rem;--bs-spinner-height: 1rem;" role="status">
                <span class="sr-only">Loading...</span>
              </div>

            </button>

          </div>
        </template>
      </template>
      <template v-else>
        <h3 style="text-align: center">There is no Distributions.</h3>
        <h5 style="text-align: center">Download it from Settings.</h5>
      </template>


      <!-- Add more camp items as needed -->
    </div>
  </div>

  <!--  <div>-->
  <!--    <span v-if="is_online" style="color: limegreen"><b>☁ Online</b></span>-->
  <!--    <span v-else><b>☹ Offline</b></span>-->
  <!--    <div v-if="user"><b>Monitor: </b> {{(user.first_name ? user.first_name : '' )+' '+(user.last_name ? user.last_name : '' )}}</div>-->

  <!--    <div class="content mt-4">-->
  <!--      <div class="row">-->
  <!--        <div class="ps-3 pe-3" v-for="(item,index) in data">-->
  <!--          <div class="btn btn-light border-secondary mb-3 p-1 w-100 d-flex justify-content-between">-->
  <!--            <router-link :to="'/visits/'+item.id" class="text-decoration-none" style="    width: 80%;padding: 7px;color: black;">{{-->
  <!--                item.camp.name_ar-->
  <!--              }}-->
  <!--              ({{ item.camp.customers_count }})-->
  <!--              <div style="font-size: 10px" v-if="item.employee">-->
  <!--                Monitor: {{ item.employee.name  }}-->
  <!--              </div>-->
  <!--              <div style="font-size: 10px" v-else>-->
  <!--                Monitor: unKnown-->
  <!--              </div>-->
  <!--              <div style="font-size: 10px" v-if="item.last_sync_at">-->
  <!--                Last Sync: {{ item.last_sync_at  }}-->
  <!--              </div>-->

  <!--            </router-link>-->


  <!--            <button v-if="user.employee_id == item.employee_id || $root.is_can_sync_activated" style="font-size: 12px;    font-weight: bold;" @click="uploadVisits(item.id,item)" :class="['btn','btn-success',!is_online || loading ? 'disabled' : '']" :disabled="!is_online || loading">-->
  <!--              Sync-->
  <!--              <span v-if="loading">..</span>-->
  <!--            </button>-->
  <!--          </div>-->

  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</template>
<script>
import {addOrUpdateData, getAllData, clearAllData, getDataByKey} from '@/database';

export default {
  components: {},
  data() {
    return {
      data: [],
      user: {},
      showInstallButton: false,
      loading: false,
      error: null,
      is_online: navigator.onLine,
    };
  },
  mounted() {
    // this.preventPullToRefresh();
    this.getScreenData();
    // this.is_online = true;
    // this.is_online = navigator.onLine;
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);

  },
  beforeDestroy() {
    // Remove event listeners to prevent memory leaks
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  methods: {
    updateOnlineStatus() {
      this.is_online = navigator.onLine; // Update the status based on the navigator's online property
    },
    async getScreenData() {
      try {
        // if (navigator.onLine) {
        //   var response = await this.$axios.get('/api/visits/current');
        //   this.data = response.data.data
        //   await addOrUpdateData(response.data.data, 'current-visits')
        // } else {
        getAllData('current-visits').then((data) => {
          this.data = data;
          for (let i = 0; i < this.data.length; i++) {
            this.data[i].sync_loader = false;
          }
        })
        getAllData('auth-user').then((data) => {
          this.user = data[0];

        })
        // }

        // getDataByKey(17182,'current-visits').then((data) => {
        //   console.log(data,'data')
        // })
      } catch (error) {
        console.log(error)
      }
    },
    async uploadVisits(event, visit_id, item,index) {
      try {
        let visit = await getDataByKey(visit_id, 'current-visits')
        this.loading = true;
        this.sync_loader = true;
        this.data[index].sync_loader = true;
        var response = await this.$axios.post('/api/visits/' + visit_id + '/sync', {
          data: visit,
          employee_id: this.user.employee_id
        });
        if (response.data.status == true) {
          this.loading = false;
          this.sync_loader = false;
          this.data[index].sync_loader = false;

          visit.last_sync_at = response.data.last_sync_at
          item.last_sync_at = response.data.last_sync_at;
          const cleanData = JSON.parse(JSON.stringify(visit));
          await addOrUpdateData(cleanData, 'current-visits')
        }
        console.log(response, 'response')
      } catch (error) {
        this.loading = false;
        this.sync_loader = false;
        this.data[index].sync_loader = false;

        console.log(error)
      }

    },
    preventPullToRefresh() {
      let startY = 0;

      document.addEventListener('touchstart', function (e) {
        startY = e.touches[0].clientY; // Record the initial touch position
      });

      document.addEventListener('touchmove', function (e) {
        const currentY = e.touches[0].clientY;

        // If scrolling down from the top of the page, prevent the default pull-to-refresh
        if (currentY > startY && window.scrollY === 0) {
          e.preventDefault();
        }
      }, {passive: false});
    }
  }
};
</script>

<style scoped>

.header-home {
  background-color: #34657f;
  color: white;
  padding: 0px;
  text-align: center;
  border-radius: 0 0 20px 20px;
}

.user-info {
  margin: 10px 0;
  font-size: 1em;
  color: white;
}

.camps-container {
  margin-top: -30px;
  padding: 20px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.camp-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  border-bottom: 1px solid #e0e0e0;
}

.camp-item:last-child {
  border-bottom: none;
}

.camp-info {
  flex-grow: 1;
  margin-right: 0px;
  text-align: left;
}

.sync-btn {
  background-color: #28a745;
  color: white;
  border-radius: 25px;
  padding: 10px 15px;
}

.sync-btn-white {
  background-color: #34657f;
  color: white;
  border-radius: 25px;
  padding: 10px 15px;
  border: 1px solid #34657f !important;
}

.sync-btn:hover {
  background-color: #218838;
}
</style>
