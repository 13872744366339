<template>
  <!-- Settings Header -->
  <div class="settings-header" style="padding: 20px 0 40px 0;font-size: 20px;">
    <h1 style="font-size: 20px;">Settings</h1>
  </div>

  <!-- Settings Container -->
  <div style="padding-right: 10px;padding-left: 10px">
    <div class="container settings-container">
      <!-- Get Distributions -->
      <div class="setting-item">
        <span>Update App</span>
        <button type="button" :class="['btn btn-warning btn-sm',!is_online || loading_reload ? 'disabled' : '']"
                @click="updateApp()" :disabled="!is_online || loading_reload">
          <font-awesome-icon icon="rotate-backward" />
          Update
        </button>
      </div>

      <!-- Logout -->
      <div class="setting-action">
        <button type="button" class="btn logout-btn" @click="logout">Logout</button>
      </div>
    </div>

  </div>


</template>
<script>
import {addOrUpdateData, getAllData, clearAllData} from "@/database";

export default {
  components: {},
  data() {
    return {
      username: '',
      password: '',
      loading: false,
      showInstallButton: false,
      error: null,
      is_online: false,
      loading_reload: false,

    };
  },
  mounted() {
    // this.is_online = true;
    this.is_online = navigator.onLine;

  },
  methods: {
    logout() {
      localStorage.removeItem('token');

      // Redirect or update the UI to indicate the user is logged in
      this.$router.push('/'); // Adjust the path as needed
    },
    async getCurrentVisitsData() {
      try {
        // if (navigator.onLine) {
        this.loading = true;
        var response = await this.$axios.get('/api/visits/current');
        this.data = response.data.data
        this.loading = false;
        await clearAllData('current-visits')
        await addOrUpdateData(response.data.data, 'current-visits')
        // } else {
        //   getAllData('current-visits').then((data) => {
        //     this.data = data;
        //   })
        // }

        // getDataByKey(17182,'current-visits').then((data) => {
        //   console.log(data,'data')
        // })
      } catch (error) {
        console.log(error)
      }
    },
    updateApp() {
    location.reload();
    }
  }
};
</script>

<style scoped>
.logo-img {
  max-width: 150px;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.settings-header {
  background-color: #34657f;
  color: white;
  padding: 0px;
  text-align: center;
  border-radius: 0 0 20px 20px;
}

.settings-container {
  margin-top: -30px;
  padding: 20px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.setting-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #e0e0e0;
}

.setting-item:last-child {
  border-bottom: none;
}

.setting-item .form-check {
  margin-right: 10px;
}

.setting-item button {
  min-width: 120px;
}

.setting-action {
  text-align: center;
  margin-top: 30px;
}

.logout-btn {
  background-color: #dc3545;
  color: white;
  padding: 12px;
  border-radius: 30px;
  width: 100%;
}
</style>
