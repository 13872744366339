<template>
  <div class="login-container">
    <!-- Logo -->
    <div class="text-center mt-5 mb-5">
      <img src="/img/icons/logo.png" alt="App Logo" class="logo-img">
    </div>

    <!-- Login Form -->
    <div class="login-form">
      <form @submit.prevent="login">
        <div class="mb-3">
          <label for="username" class="form-label">Username</label>
          <input type="text" class="form-control" id="username" v-model="username" required>
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Password</label>
          <input type="password" class="form-control" id="password" v-model="password" required>
        </div>
        <div v-if="error">{{ error }}</div>
        <button type="submit" class="btn btn-primary w-100">Login</button>
      </form>
    </div>

    <!-- Install PWA Button -->
    <div class="text-center mt-5">
      <button class="btn btn-success" v-if="showInstallButton" @click="installPWA">Install Application</button>
    </div>
  </div>
</template>
<script>
import {addOrUpdateData, clearAllData} from "@/database";

export default {
  data() {
    return {
      username: '',
      password: '',
      showInstallButton: false,
      error: null
    };
  },
  mounted() {
    window.addEventListener('beforeinstallprompt', (e) => {
      window.deferredPrompt = e;
      this.showInstallButton = true;
    });
  },
  methods: {
    async login() {
      try {
        // Make the initial request to get the CSRF token

        // Attempt to log in
        const response = await this.$axios.post('/api/login', {
          username: this.username,
          password: this.password
        });
        await clearAllData('auth-user')
        await addOrUpdateData(response.data.data, 'auth-user')

        // Store the token in localStorage
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', response.data.data);
        // Redirect or update the UI to indicate the user is logged in
        if (response.data.data.role && (response.data.data.role.name == 'store-employee' || response.data.data.role.name == 'accountant')){
          this.$router.push('/current-delivery-commodities'); // Adjust the path as needed
        }else{
          this.$router.push('/home'); // Adjust the path as needed
        }
      } catch (error) {
        console.log(error)
        this.error = 'Login failed. Please check your credentials.';
      }
    },
    installPWA() {
      // Show the install prompt
      window.deferredPrompt.prompt();
    }

  }
};
</script>

<style scoped>
.logo-img {
  max-width: 150px;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.login-form {
  max-width: 300px; /* Adjust the maximum width of the login form */
  width: 100%;
}
</style>
