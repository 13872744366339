<template>
  <GMapMap
      :center="{ lat: 37.7749, lng: -122.4194 }"
      :zoom="12"
      style="width: 100%; height: 400px"
  >
    <GMapMarker :position="{ lat: 37.7749, lng: -122.4194 }" />
  </GMapMap>
</template>

<script>
export default {
  name: 'MapComponent',
};
</script>

<style scoped>
/* Optional custom styles */
</style>
