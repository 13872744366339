<template>
  <!-- delete-service -->
  <div class="modal fade delete-category" id="show-delivery-info" tabindex="-1" aria-labelledby=" delete-serviceLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body pb-0">
          <h2>Information</h2>
          <div v-if="item.delivery_type == 'camp'" class="row">
            <div class="col-md-12">
              <label>English Name</label>
              <input type="text" readonly disabled class="form-control text-center" style="font-size: 12px" :value="(item.camp ? item.camp.name : '')">
            </div>
            <div class="col-md-12">
              <label>Arabic Name</label>
              <input type="text" readonly disabled class="form-control text-center" style="font-size: 12px" :value="(item.camp ? item.camp.name_ar : '')">
            </div>
            <div class="col-md-12">
              <label>Governorate</label>
              <input type="text" readonly disabled class="form-control text-center" style="font-size: 12px" :value="(item.camp ? item.camp.city_name : '')">
            </div>
            <div class="col-md-12">
              <label>Comm Leader</label>
              <input type="text" readonly disabled class="form-control text-center" style="font-size: 12px" :value="(item.camp ? item.camp.rep_name : '')">
            </div>
            <div class="col-md-12">
              <label>Comm Leader Phone</label>
              <input type="text" readonly disabled class="form-control text-center" style="font-size: 12px" :value="(item.camp ? item.camp.rep_phone : '')">
            </div>
            <div class="col-md-12">
              <label>Comm Leader Phone</label>
              <input type="text" readonly disabled class="form-control text-center" style="font-size: 12px" :value="(item.camp ? item.camp.rep_phone : '')">
            </div>
            <div class="col-md-12">
              <label>City Name</label>
              <input type="text" readonly disabled class="form-control text-center" style="font-size: 12px" :value="(item.camp ? item.camp.city_name : '')">
            </div>
            <div class="col-md-12">
              <label>Monitor</label>
              <input type="text" readonly disabled class="form-control text-center" style="font-size: 12px" :value="(item.employee ? item.employee.name : '')">
            </div>

          </div>
          <div v-else class="row">
            <div class="col-md-12">
              <label for="identity">Identity</label>
              <input type="text" id="identity" readonly disabled class="form-control text-center" style="font-size: 12px" :value="(item.customer ? item.customer.identity : '')">
            </div>

            <div class="col-md-12">
              <label for="full_name">Full name</label>
              <input type="text" id="full_name" readonly disabled class="form-control text-center" style="font-size: 12px" :value="(item.customer ? item.customer.full_name : '')">
            </div>
            <div class="col-md-12">
              <label for="mobile">Mobile</label>
              <input type="text" id="mobile" readonly disabled class="form-control text-center" style="font-size: 12px" :value="(item.customer ? item.customer.mobile : '')">
            </div>
            <div class="col-md-12 mb-2">
              <label for="family_count">Family count</label>
              <input type="text" id="family_count" readonly disabled class="form-control text-center" style="font-size: 12px" :value="(item.customer ? item.customer.family_count : '')">
            </div>


          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus';
import {addOrUpdateData, getAllData, getDataByKey} from "@/database";

export default {
  name: 'delete-services',
  components: {},
  data() {
    return {
      item: {
        notes: 'تم تسليم الكمية حسبب العدد المطلوب',
      },
      notes: 'تم تسليم الكمية حسبب العدد المطلوب',
      user: null,
      search_value: null,
      current_page: null,
      loading: false,

    }
  },
  async mounted() {
    eventBus.on('selected_item_for_done_delivery', function (item) {
      this.item = item.item
      getAllData('auth-user').then((data) => {
        this.user = data[0];

      })
    }.bind(this))
  },


  methods: {
    async confirm_complete(event, visit_id, item) {
      try {
        let visit = await getDataByKey(visit_id, 'current-visits')
        this.loading = true;
        var response = await this.$axios.post('/api/visits/' + visit_id + '/store-delivery-accept', {
          data: this.item.items,
          notes: this.notes,
          employee_id: this.user.employee_id
        });
        $('#done-delivery').modal('toggle')
        eventBus.emit('refresh-list')
        this.loading = false;
        console.log(response, 'response')
      } catch (error) {
        this.loading = false;
        console.log(error)
      }

    },
    async reject_complete(event, visit_id, item) {
      try {
        let visit = await getDataByKey(visit_id, 'current-visits')
        this.loading = true;
        var response = await this.$axios.post('/api/visits/' + visit_id + '/store-delivery-reject', {
          data: this.item.items,
          notes: this.notes,
          employee_id: this.user.employee_id
        });
        this.loading = false;
        eventBus.emit('refresh-list')
        $('#done-delivery').modal('toggle')
        console.log(response, 'response')
      } catch (error) {
        this.loading = false;
        console.log(error)
      }

    },
    showError(error) {
      let message_title = error.response.data.message + '\n';
      let message = message_title;
      let errors = error.response.data.errors;
      $.each(errors, function (key, val) {
        $.each(val, function (key2, val2) {
          message += val2 + '\n'
        });
      });
      this.notify("error", message, 'fas fa-times p-1', 'danger')
    },

  }
}
</script>
<style scoped>
.staff-members >>> .multiselect__tags {
  margin-left: -1px !important;
  border: none !important;
}
</style>
