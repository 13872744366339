<!-- src/components/SplashScreen.vue -->
<template>
  <div v-if="isVisible" class="splash-screen">
    <div class="splash-content">
      <img src="@/assets/logo.png" alt="Logo" class="logo" />

      <h1 class="mt-3">AMS Aid Distributions </h1>
<!--      <p>Loading...</p>-->
      <!-- Add any logo or image here -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: true,
    };
  },
  mounted() {
    // Simulate loading time
    setTimeout(() => {
      this.isVisible = false;
    }, 3); // Adjust time as needed
  },
};
</script>

<style scoped>
.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff; /* Customize background color */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9999; /* Make sure it's on top */
}

.splash-content {
  text-align: center;
}

.logo {
  width: 150px; /* Adjust the logo size */
  margin-top: 20px;
}
</style>
