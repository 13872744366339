<template>
  <div class="content mt-0" style="padding-top: 0">
    <div class="row" style="margin: 0">
      <div class="ps-3 pe-3">
<!--        <h3>{{ data.camp ? data.camp.name_ar : '' }}</h3>-->
        <div style="    padding: 10px 10px 10px;  position: fixed;  left: 0;    top: 50px;  width: 100%;  background: #f8f9fa;">
          <div v-if="data.camp" >
            <a href="javascript:void(0);" @click="copyCoordinates(data.camp.location)" :style="(location_copied ? 'background: #eaffd2;' : '')+'padding: 2px 8px;text-decoration: none'" style="">
              Copy Location
              <b style="font-style: italic;" v-if="location_copied">Copied</b>
            </a>
          </div>
          <input :type="(search_by_no && !search_by_id_name) ? 'tel' : 'search'" v-model="searchQuery" class="form-control"
                 style="font-size: 14px !important;height: 50px !important;width: 95%;margin: 0 auto;"
                 placeholder="Search by no, identity and name">
          <div class="d-flex justify-content-center mt-2">
            <div>
              <input type="checkbox" class="btn-check" id="btn-check-outlined-1" @change="filteredCustomers" v-model="search_by_no">
              <label class="btn btn-outline-primary" for="btn-check-outlined-1">By No.</label><br>
            </div>
            <div class="mx-2">
              <input type="checkbox" class="btn-check" id="btn-check-outlined-2" @change="filteredCustomers" v-model="search_by_id_name"  >
              <label class="btn btn-outline-primary" for="btn-check-outlined-2">By Identity and Name</label><br>
            </div>

          </div>
          <p style="padding-top: 4px !important;margin-bottom: 0;">✅ Checked beneficiaries {{ receivedCount }}</p>

        </div>
        <ul class="list-unstyled px-1" style="    margin-top: 170px;">

          <li v-for="(customer,index) in filteredCustomers()" :key="customer.id">
            <div class="d-flex justify-content-end" style="text-align: right;direction: rtl">
              <div><input type="text" style="width: 180px;border-radius: 3px;border: 1px solid #aaaaaa;"
                          @input="updateReceivedList" v-model="customer.full_name"></div>
              <div class="mx-1">
                <input type="tel" @input.prevent="updateReceivedList"
                       :style="'border-radius: 3px;border: 1px solid #aaaaaa;width: 100px;text-align: left'+(customer.identity_check_code == 2 ? ';color:red' : '')"
                       v-model="customer.identity">
              </div>
              <div><input type="checkbox" @change="updateReceivedList" v-model="customer.is_received"
                          style="width: 30px;height: 30px;background-color: limegreen"></div>
              <div class="mx-1" style="width: 30px;text-align: left">{{ customer.indexId }}</div>
            </div>
          </li>
          <li>
            <button class="btn btn-success" @click="addNewBeneficiary">
              Add new beneficiary +
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import {addOrUpdateData, getAllData, clearAllData, getDataByKey} from '@/database';

export default {
  components: {},
  data() {
    return {
      data: {
        camps: null,
        customers: [],
      },
      searchQuery: '',
      showInstallButton: false,
      receivedCount: 0,
      error: null,
      search_by_no: true,
      search_by_id_name: false,
      location_copied: false,
    };
  },
  mounted() {
    this.getScreenData();

  },
  methods: {
    async getScreenData() {
      try {
        await getDataByKey(Number(this.$route.params.id), 'current-visits').then((data) => {
          console.log(data, 'data')
          this.data = data;
          if (data.camp)
            $('#page-title').text(data.camp.name_ar);

          this.getReceivedCount();
          this.$forceUpdate();
        })

      } catch (error) {
        console.log(error)
      }
    },
    filteredCustomers() {
      if (!this.searchQuery) {
        for (let i = 0; i < this.data.customers.length; i++) {
          this.data.customers[i].indexId = i + 1;
        }
        return this.data.customers;
      }
      return this.data.customers.filter((customer) => {
        const searchLower = this.searchQuery.toLowerCase();
        return (
            ( this.search_by_id_name && customer.identity && customer.identity.toString().includes(this.searchQuery)) ||
            (this.search_by_id_name && customer.full_name && customer.full_name.toLowerCase().includes(searchLower)) ||
           (this.search_by_no && customer.indexId == (this.searchQuery))
        );
      });
    },
    async updateReceivedList() {
      const cleanData = JSON.parse(JSON.stringify(this.data));
      await addOrUpdateData(cleanData, 'current-visits')
      this.getReceivedCount();
    },
    async addNewBeneficiary() {
      this.receivedCount = this.data.customers.filter(customer => customer.is_received).length;
      this.data.customers.push({id:'new-'+(this.data.customers.length + 1),identity:null,full_name:null,is_received:true})
      const cleanData = JSON.parse(JSON.stringify(this.data));
      await addOrUpdateData(cleanData, 'current-visits')
      this.getReceivedCount();
    },
    getReceivedCount() {
      this.receivedCount = this.data.customers.filter(customer => customer.is_received).length;

    },
    copyCoordinates(location, index) {
      navigator.clipboard.writeText(location)
          .then(() => {
            this.location_copied = true;
            setTimeout(function () {
              this.location_copied = false;
            }.bind(this), 1500)
          })
          .catch(err => {
            console.error('Could not copy text: ', err);
          });
    },
  }
};
</script>

<style scoped>
.logo-img {
  max-width: 150px;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.login-form {
  max-width: 300px; /* Adjust the maximum width of the login form */
  width: 100%;
}
</style>
